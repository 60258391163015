import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faPhone,
    faExclamationTriangle,
    faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { CtaBlock } from './CtaBlock';
import { InputWithValidation } from './InputWithValidation';
import { InputValidation } from './InputValidation';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { TextUtils } from '../../../../hexagon/shared/utils/TextUtils';
import { unsubscribeUseCase } from '../../../../hexagon/usecases/unsubscribe/unsubscribe.useCase';
import { getUnsubscribeSelector } from '../../view-models-generators/unsubscribeSelectors';
import useTranslation from '../hooks/useTranslation';

export const UnsubscribePage: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { client } = useSelector(getClientSelector);

    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [phoneValid, setPhoneValid] = useState<boolean | undefined>(undefined);
    const [emailValid, setEmailValid] = useState<boolean | undefined>(undefined);
    const [formValid, setFormValid] = useState<boolean>(false);
    const [touched, setTouched] = useState<boolean>(false);

    const { status } = useSelector(getUnsubscribeSelector);

    const checkForm = () => {
        if (phone) {
            setPhoneValid(phone.search(new RegExp(client.config.phoneRegex)) === 0);
        } else {
            setPhoneValid(undefined);
        }
        setEmailValid(TextUtils.isEmailValid(email));
    };

    useEffect(() => {
        checkForm();
    }, [dispatch, phone, email]);

    useEffect(() => {
        setFormValid(phoneValid !== false && emailValid === true);
    }, [dispatch, phoneValid, emailValid]);

    const handleSubmit = () => {
        if (formValid) {
            dispatch(unsubscribeUseCase(email, phone));
        }
        setTouched(true);
    };

    const handleChange = (value: string) => {
        if (value.length <= 10) setPhone(value);
    };

    let emailInputClass = '';
    let phoneInputClass = '';

    if (touched) {
        emailInputClass = emailValid === false ? 'input-danger' : 'input-success';
        phoneInputClass = phoneValid === false ? 'input-danger' : 'input-success';
    }

    return (
        <div className="page page-unsubscribe pt-5 pb-2">
            <Container fluid className="m-2">
                <h1>{t('unsubscription_page_title')}</h1>
                <p className="mb-5">{t('unsubscription_page_detail')}</p>

                <Row className="form-unsubscribe mb-3">
                    <Col xs={12} sm={6}>
                        <FormGroup>
                            <Label htmlFor="email">{t('unsubscription_page_email')}</Label>
                            <InputWithValidation>
                                <InputGroup className={emailInputClass}>
                                    <Input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder={t('unsubscription_page_email_detail')}
                                        defaultValue={email}
                                        className={emailInputClass}
                                        onBlur={(e) => {
                                            setEmail(e.currentTarget.value);
                                            setTouched(false);
                                        }}
                                        disabled={status === 'succeeded'}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <FontAwesomeIcon color="#10cfc9" icon={faEnvelope} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                <InputValidation valid={touched ? emailValid : undefined} />
                            </InputWithValidation>
                            {touched && email && !emailValid && (
                                <p className="text-danger small">
                                    {t('unsubscription_page_wrong_email')}
                                </p>
                            )}
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={6}>
                        <FormGroup>
                            <Label htmlFor="phone">{t('unsubscription_page_phone')}</Label>
                            <InputWithValidation>
                                <InputGroup className={phoneInputClass}>
                                    <Input
                                        type="number"
                                        id="phone"
                                        name="phone"
                                        value={phone}
                                        placeholder={t('unsubscription_page_phone_detail')}
                                        className={phoneInputClass}
                                        onBlur={(e) => {
                                            setPhone(e.currentTarget.value);
                                            setTouched(false);
                                        }}
                                        onChange={(e) => handleChange(e.currentTarget.value)}
                                        disabled={status === 'succeeded'}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <FontAwesomeIcon color="#10cfc9" icon={faPhone} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                <InputValidation valid={phoneValid} />
                            </InputWithValidation>
                            {touched && phoneValid === false && (
                                <p className="text-danger small">
                                    {t('unsubscription_page_wrong_phone')}
                                </p>
                            )}
                        </FormGroup>
                    </Col>
                    <Col>
                        {touched && formValid && (
                            <div className="unsubscribe-return valid">
                                <div className="unsubscribe-return-icon">
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                                <div className="unsubscribe-return-message">
                                    {t('unsubscription_page_confirmation')}
                                </div>
                            </div>
                        )}
                        {touched && !email && (
                            <div className="unsubscribe-return error">
                                <div className="unsubscribe-return-icon">
                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                </div>
                                <div className="unsubscribe-return-message">
                                    {t('unsubscription_page_email_mandatory')}
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                    <CtaBlock>
                        <Button color="primary" onClick={handleSubmit} className="mb-5">
                            {t('unsubscription_page_cta')}
                        </Button>
                    </CtaBlock>
                </Row>
            </Container>
        </div>
    );
};
