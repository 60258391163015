import { AnyAction } from 'redux';

export const coteCQ = (state = false, action: AnyAction) => {
    if (action.type === 'checkCoteCQ/FAILED') {
        return false;
    }
    if (action.type === 'checkCoteCQ/SUCCESS') {
        return true;
    }
    return state;
};

export const coteCQReducer = coteCQ;
