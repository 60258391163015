import React from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

export const GeneralConditions: React.FC = () => (
    <>
        <Helmet>
            <title>Conditions générales</title>
            <meta name="robots" content="noindex" />
        </Helmet>

        <div className="page page-general-conditions">
            <Container>
                <div className="text-center my-5">
                    <h1>Conditions générales</h1>
                </div>
            </Container>
        </div>
    </>
);
