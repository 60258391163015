import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import '../Themes/spoticar/starRating.scss';
import { useSelector } from 'react-redux';
import useTranslation from '../hooks/useTranslation';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import expertPicture from '../Themes/spoticar/img/expert_img.png';

type TDealerComponentProps = {
    pointOfSale: any;
    dealer: any;
    setDealer: any;
};

const DealerComponent: React.FC<TDealerComponentProps> = ({ pointOfSale, dealer, setDealer }) => {
    const { t } = useTranslation();
    const { client } = useSelector(getClientSelector);
    const { locale, currency } = client.config;

    const addDefaultSrc = (target: any) => {
        target.src = expertPicture;
    };

    return (
        <div
            className={`button-dealer ${pointOfSale.id === dealer.id ? 'selected' : ''}`}
            key={pointOfSale.id}
            role="button"
            aria-hidden="true"
            onClick={() => {
                setDealer({
                    id: pointOfSale.id,
                    name: pointOfSale.name,
                    distance: pointOfSale.distance,
                });
            }}
        >
            <div className="button-dealer-distance">
                <FontAwesomeIcon icon={faMapMarkerAlt} /> {pointOfSale.distance} km
            </div>
            <div className="button-dealer-icon">
                <input
                    id={`radio-${pointOfSale.id}`}
                    className="radio-custom"
                    name="radio-group"
                    type="radio"
                />
                <label htmlFor={`radio-${pointOfSale.id}`} className="radio-custom-label" />
            </div>
            <div className="button-dealer-avatar">
                <img
                    onError={(e) => addDefaultSrc(e.target)}
                    src={
                        pointOfSale.experts[0].picture
                            ? pointOfSale.experts[0].picture
                            : expertPicture
                    }
                    className="userimg"
                    alt=""
                />
            </div>
            <div>
                <div className="button-dealer-expert">{pointOfSale.experts[0].firstName} </div>
                <div className="button-dealer-name">
                    {t('point_of_sale')} {pointOfSale.c2bName}{' '}
                    {/* {pointOfSale.city.charAt(0).toUpperCase() +
                        pointOfSale.city.slice(1).toLowerCase()} */}
                </div>
                <div className="button-dealer-review">
                    {pointOfSale.stars && (
                        <div
                            className={`sprite-etoile-bg  sprite-etoile-${pointOfSale.stars}  sprite-etoile-${pointOfSale.stars}-dims inline-block`}
                        />
                    )}
                    {pointOfSale.totalReviewers && (
                        <div>
                            {pointOfSale.totalReviewers} {t('review')}
                        </div>
                    )}
                </div>

                {/* {pointOfSale.ajustement && (
                    <div className="button-dealer-bonus">
                        <span className="font-weight-bold">
                            + {TextUtils.formatPrice(locale, currency, pointOfSale.ajustement)}
                        </span>{' '}
                        <span
                            dangerouslySetInnerHTML={{
                                __html: pointOfSale.label || '',
                            }}
                        />
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default DealerComponent;
