import { Mapper } from '../../../../../hexagon/infra/Mapper';
import { TReason } from '../../../../../hexagon/interfaces';
import { AutobizCancellationReasonDto } from '../dtos/cancellationReasonDto';

export class CancellationReasonMapper implements Mapper<TReason> {
    static toApp(dto: AutobizCancellationReasonDto): TReason {
        return {
            id: Number(dto.Id),
            label: dto.ReasonOfCancellation,
        };
    }
}
