import { ActionsUnion, createAction } from '../../../redux/customActions';
import { Dealer } from '../../interfaces';

export const Actions = {
    UpdateDealerListPending: () => createAction('dealerList/UPDATE/PENDING'),
    UpdateDealerListSucceed: (dealerList: Dealer[]) =>
        createAction('dealerList/UPDATE/SUCCESS', { dealerList }),
    UpdateDealerListFailed: () => createAction('dealerList/UPDATE/FAILED'),
};

export type ActionsType = ActionsUnion<typeof Actions>;
