import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import useTranslation from '../hooks/useTranslation';
import { getBuyingProjectMakeSelector } from '../../view-models-generators/buyingProjectMakeSelector';

const NewVehiclePurchaseLoader = () => {
    const { t } = useTranslation();

    const { name } = useSelector(getBuyingProjectMakeSelector);
    return (
        <div className="page page-loader-redirection">
            <Container>
                <Row className="p-5">
                    <h1>
                        {(t('buying_project_intermediate_message_title') || '').replace(
                            '[MARQUE_RECHERCHEE]',
                            name,
                        )}
                    </h1>
                    <>
                        {(t('buying_project_intermediate_message_content') || '').replace(
                            '[MARQUE_RECHERCHEE]',
                            name,
                        )}
                    </>
                </Row>
            </Container>
        </div>
    );
};

export default NewVehiclePurchaseLoader;
