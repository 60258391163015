import _ from 'lodash';
import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';

export const isPostponedUseCase =
    (isPosponed: boolean): ThunkResult<void> =>
    async (dispatch) => {
        dispatch(actionCreators.Actions.isPostponedPending());
        if (isPosponed) {
            dispatch(actionCreators.Actions.isPostponedSucceed());
        } else {
            dispatch(actionCreators.Actions.isPostponedFailed());
        }
    };
