import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { InputWithValidation } from './InputWithValidation';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import useTranslation from '../hooks/useTranslation';
import { CtaBlock } from './CtaBlock';
import { submitRadApplicationUseCase } from '../../../../hexagon/usecases/submitRadApplication/submitRadApplication.useCase';
import { getSmsSentSelector } from '../../view-models-generators/smsSentSelector';

type TPhoneInputProps = {
    customerPhone?: string;
};

export const RestitutionPhoneInput: React.FC<TPhoneInputProps> = ({ customerPhone }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const { client } = useSelector(getClientSelector);

    const [phone, setPhone] = useState<string>(customerPhone || '');
    const [phoneValid, setPhoneValid] = useState<boolean | undefined>();

    const { status } = useSelector(getSmsSentSelector);

    const checkForm = () => {
        if (phone) {
            setPhoneValid(phone.search(new RegExp(client.config.phoneRadRegex)) === 0);
        } else {
            setPhoneValid(undefined);
        }
    };
    useEffect(() => {
        checkForm();
    }, [phone]);

    const handleSubmit = () => {
        if (phoneValid && status !== 'pending') dispatch(submitRadApplicationUseCase(phone));
    };

    useEffect(() => {
        if (status === 'succeeded') {
            history.push('/confirmation');
        }
    }, [dispatch, status]);

    const handleChange = (value: string) => {
        if (value.length <= 10) setPhone(value);
    };
    return (
        <FormGroup>
            <Row className="restitution-phone-input">
                <Col xs={12} sm={6}>
                    <Label for="phone">{t('hbs_page_phone')}</Label>
                    <div className="input-with-validation">
                        <InputGroup>
                            <Input
                                invalid={!phoneValid && phone !== ''}
                                type="number"
                                id="phone"
                                name="phone"
                                className="input-rad"
                                value={phone}
                                placeholder={t('hbs_page_phone_format')}
                                onChange={(e) => handleChange(e.currentTarget.value)}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText
                                    style={{
                                        border: !phoneValid && phone !== '' ? '1px solid red' : '',
                                        borderLeft: 'none',
                                    }}
                                >
                                    <FontAwesomeIcon color="#10cfc9" icon={faPhone} rotation={90} />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                        {!_.isUndefined(phone) && (
                            <div className="input-validation input-validation-restitution-tel">
                                {phoneValid === true && <FontAwesomeIcon icon={faCheck} />}
                                {phoneValid === false && <FontAwesomeIcon icon={faTimes} />}
                            </div>
                        )}
                    </div>
                </Col>
                <Col xs={12} sm={6}>
                    <Button
                        color="primary"
                        className="w-100"
                        disabled={!phone}
                        onClick={handleSubmit}
                    >
                        {t('hbs_page_cta')}
                    </Button>
                </Col>
            </Row>
        </FormGroup>
    );
};
