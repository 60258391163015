import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';

const { Actions } = actionCreators;

export const setUtmCampaign =
    (utm_campaign: string): ThunkResult<void> =>
    async (dispatch) => {
        dispatch(Actions.setUtmCampaign(utm_campaign));
    };
