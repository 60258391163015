import { ThunkResult } from '../../../redux/configureStore';
import { TParticularItem } from '../../interfaces';
import * as actionCreators from './actionCreators';

const { Actions } = actionCreators;

export const setParticularValue =
    (key: TParticularItem, value: string): ThunkResult<void> =>
    async (dispatch) => {
        dispatch(Actions.setParticular({ key, value }));
    };
