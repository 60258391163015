import { ActionsUnion, createAction } from '../../../redux/customActions';
import { TRecordType } from '../../interfaces';

export const Actions = {
    recordInformationFetching: () => createAction('recordInformation/PENDING'),
    recordInformationFailed: () => createAction('recordInformation/FAILED'),
    recordInformationRetrieved: (recordType?: TRecordType) =>
        createAction('recordInformation/SUCCESS', { recordType }),
};

export type ActionsType = ActionsUnion<typeof Actions>;
