import { ThunkResult } from '../../../redux/configureStore';
import { getDealerListUseCase } from '../getDealerList/getDealerList.useCase';
import { getRecordUseCase } from '../getRecord/getRecord.useCase';
import { updateDealerListUseCase } from '../updateDealerList/updateDealerList.useCase';
import * as actionCreators from './actionCreators';

export const getRecordInformationUseCase =
    (recordUid: string): ThunkResult<void> =>
    async (dispatch, getState) => {
        dispatch(actionCreators.Actions.recordInformationFetching());

        await dispatch(getRecordUseCase(recordUid, 'full'));
        await dispatch(getDealerListUseCase(recordUid));
        await dispatch(updateDealerListUseCase());

        const {
            data: { isRad, offerStatus },
        } = getState().record;
        const { closePOS, coteCQ, isPostponed } = getState();

        if (offerStatus !== 'NO_APPOINTMENT' && !isPostponed)
            return dispatch(actionCreators.Actions.recordInformationRetrieved());

        // CASE WHEN NO CLOSE PDV AND VEHICLE TIME DETENTION > 0
        if (coteCQ) return dispatch(actionCreators.Actions.recordInformationRetrieved('COTE_CQ'));

        // CASE WHEN NO CLOSE PDV AND VEHCILE ELLIGBLE TO RAD (100% RAD)
        if (!closePOS && isRad)
            return dispatch(actionCreators.Actions.recordInformationRetrieved('HBS_ONLY'));

        // CASE WHEN NO CLOSE PDV AND VEHCILE NOT ELLIGBLE TO RAD (NO PDV AND RAD FALSE)
        if (!closePOS && !isRad)
            return dispatch(
                actionCreators.Actions.recordInformationRetrieved('APPOINTMENT_NO_POS'),
            );

        // CASE WHEN CLOSE PDV EXIST AND VEHCILE ELLIGBLE TO RAD (PDV AND RAD TRUE)
        if (closePOS && isRad)
            return dispatch(actionCreators.Actions.recordInformationRetrieved('HBS_APPOINTMENT'));

        // CASE WHEN CLOSE PDV EXIST AND VEHCILE NOT ELLIGBLE TO RAD (PDV AND RAD FALSE)
        if (closePOS && !isRad)
            return dispatch(actionCreators.Actions.recordInformationRetrieved('APPOINTMENT_ONLY'));

        return dispatch(actionCreators.Actions.recordInformationRetrieved());
    };
