/*eslint-disable */
import { left, right } from 'fp-ts/Either';
import { BaseApi } from '../../../../hexagon/infra/BaseApi';
import { TSubscription } from '../../../../hexagon/interfaces';
import { ApiResponse } from '../../../../hexagon/infra/ApiResponse';
import { TrackerGateway } from '../../../../hexagon/gateways/trackerGateway.interface';
import { TranslationGateway } from '../../../../hexagon/gateways/translationGateway.interface';
import axios from 'axios';
import { translationLinks } from '../../../../config';

export class HttpTranslationGateway implements TranslationGateway {
    async requestTranslations(
        stage: string,
        projectName: 'spoticar',
        lang: string,
    ): Promise<ApiResponse<any>> {
        try {
            const stage = process.env.REACT_APP_ENV === 'prod' ? 'prod' : 'dev';

            //const response = await axios.get(`https://translations-host-${stage}.s3-eu-west-1.amazonaws.com/${projectName}/${lang}/locale.json`);
            const response = await axios.get(
                `https://${translationLinks[projectName][stage]}.execute-api.eu-central-1.amazonaws.com/${stage}/translations/${projectName}-iframe/${lang}`,
            );

            if (response.status) {
                return right(response.data);
            } else {
                return left('error');
            }
        } catch (error) {
            return left(error as string);
        }
    }
}
