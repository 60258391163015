import { BuyingProjectMake } from '../../../../../hexagon/interfaces';

export const someMakes: BuyingProjectMake[] = [
    {
        id: 100,
        name: 'CITROEN',
        link: 'https://www.reprise.citroen.fr/',
    },
    {
        id: 101,
        name: 'FIAT',
        link: 'https://www.reprise.dsautomobiles.fr/',
    },
    {
        id: 0,
        name: 'AUTRES MARQUES',
    },
];
