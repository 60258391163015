import { ThunkResult } from '../../../redux/configureStore';
import { TVehicleStateItem } from '../../interfaces';
import * as actionCreators from './actionCreators';

const { Actions } = actionCreators;

export const setVehicleStateValue =
    (key: TVehicleStateItem, value?: string): ThunkResult<void> =>
    async (dispatch) => {
        dispatch(Actions.setVehicleState({ key, value }));
    };
