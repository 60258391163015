import { BuyingProjectMake } from '../../../hexagon/interfaces';
import { AppState, TActionStatus } from '../../../redux/appState';

export interface ListVm<T> {
    data: T[];
    status: TActionStatus;
}
export const getBuyingProjectMakeListSelector = (state: AppState): ListVm<BuyingProjectMake> => ({
    status: state.buyingProjectMakeList.status,
    data: state.buyingProjectMakeList.data,
});
