import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import { RecordGateway } from '../../gateways/recordGateway.interface';
import { createIndicatorUseCase } from '../createIndicator/createIndicator.useCase';
import { dislayErrorUseCase } from '../displayError/displayError.useCase';
import * as actionCreators from './actionCreators';

export const submitRadApplicationUseCase =
    (phone: string): ThunkResult<void> =>
    async (dispatch, getState, { recordGateway }: { recordGateway: RecordGateway }) => {
        dispatch(actionCreators.Actions.submitRadApplicationSaving());
        const { record } = getState();
        const { config } = getState().client;

        const resultSendSms = await recordGateway.sendSms(config.identifier, record.uid, phone);

        if (isRight(resultSendSms)) {
            dispatch(actionCreators.Actions.submitRadApplicationSaved());
            await recordGateway.sendMail(
                config.identifier,
                record.uid,
                'appointment_confirmation_mail_with_hbs_id',
            );

            dispatch(createIndicatorUseCase(record.uid, 'spoticar_hbs_request', 1));
        } else {
            dispatch(actionCreators.Actions.submitRadApplicationFailed());
            dispatch(dislayErrorUseCase('send_sms_failed'));
        }
    };
