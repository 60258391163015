import { AnyAction } from 'redux';

export const buyingProjectMake = (state = {}, action: AnyAction) => {
    if (action.type === 'buying_Project_Make/SET_BuyingProjectMake') {
        return action.payload.buyingProjectMake;
    }

    return state;
};

export const buyingProjectMakeReducer = buyingProjectMake;
