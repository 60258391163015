import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import { RecordGateway } from '../../gateways/recordGateway.interface';
import { dislayErrorUseCase } from '../displayError/displayError.useCase';
import * as actionCreators from './actionCreators';

export const duplicateRecordUseCase =
    (recordUid: string): ThunkResult<void> =>
    async (dispatch, getState, { recordGateway }: { recordGateway: RecordGateway }) => {
        dispatch(actionCreators.Actions.recordFetching());
        const { config } = getState().client;
        const { isRad } = getState().record.data;

        const { closePOS } = getState();

        const result = await recordGateway.duplicateRecord(config.identifier, recordUid);

        if (isRight(result)) {
            await recordGateway.createQuotation(config.identifier, result.right.uid);
            dispatch(actionCreators.Actions.recordRetrieved(result.right.uid));

            await recordGateway.sendMail(
                config.identifier,
                recordUid,
                isRad && !closePOS ? 'restitution_mail_with_hbs' : 'restitution_mail_without_hbs',
            );
        } else {
            dispatch(actionCreators.Actions.recordFailed());
            dispatch(dislayErrorUseCase('duplication_failed'));
        }
    };
