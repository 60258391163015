import { Mapper } from '../../../../../hexagon/infra/Mapper';
import { AutobizPurchaseProjectDto } from '../dtos/purchaseProjectDto';

export class PurchaseProjectMapper implements Mapper<string> {
    static toAutobiz(
        identifier: string,
        purchaseProject: string,
        buyingProjectMake?: string,
    ): AutobizPurchaseProjectDto {
        return {
            identifier,
            purchaseProjectType: Number(purchaseProject),
            purchaseProjectBrandId: Number(buyingProjectMake),
        };
    }
}
