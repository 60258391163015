import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';

export const checkCoteCQUseCase =
    (isCoteCQ: boolean): ThunkResult<void> =>
    async (dispatch) => {
        if (isCoteCQ) {
            dispatch(actionCreators.Actions.checkCoteCQSucceed());
        } else {
            dispatch(actionCreators.Actions.checkCoteCQFailed());
        }
    };
