import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import useTranslation from '../hooks/useTranslation';
import { TRecord } from '../../../../hexagon/interfaces';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { FeatureGroup } from './FeatureGroup';
import { TextUtils } from '../../../../hexagon/shared/utils/TextUtils';
import { Feature } from './Feature';
import HowToWorkSection from './HowToWorkSection';
import { RestitutionPhoneInput } from './RestitutionPhoneInput';
import { getRecordSelector } from '../../view-models-generators/recordSelectors';
import useTracker from '../hooks/useTracker';

const RadRestitution: React.FC<TRecord> = (props) => {
    const { t } = useTranslation();

    const { vehicle, valuation, offerNumber } = props;
    const { client } = useSelector(getClientSelector);
    const { locale, currency, lang } = client.config;
    const {
        data: { customer },
        recordPSA,
    } = useSelector(getRecordSelector);
    const { trackerPushSpoticarDetails, trackerPushSpoticarVirtualPath } = useTracker();

    useEffect(() => {
        trackerPushSpoticarDetails({});
        trackerPushSpoticarVirtualPath({
            event: 'updatevirtualpath',
            formsName: 'trade in request',
            virtualPageURL: 'spoticar/vendez-votre-voiture/home-buying-service',
            brand: 'spoticar',
            pageName: `spoticar/reprise/central/B2C/used cars/${client.deviceType}/NA/hbs-page`,
            language: 'fr',
            country: 'fr',
            siteTypeLevel1: 'spoticar',
            siteTypeLevel2: 'reprise',
            siteOwner: 'central',
            siteTarget: 'B2C',
            siteFamily: 'used cars',
            pageCategory: 'lead page',
            formsLeadType: 'hot lead',
            formsPostalCode: customer.zipCode,
            mainStepIndicator: '2e',
            mainStepName: 'home-buying-service',
            vehicleModelBodystyle: 'NA',
            vehicleModelBodystyleLabel: 'NA',
            vehicleBrand: vehicle.makeName,
        });
    }, []);

    return (
        <div className="page page-restitution">
            <Container fluid className="px-0 mt-4">
                <div className="text-center">
                    <h1 className="valuation-title">
                        {recordPSA ? t('hbs_page_title_for_redirected_leads') : t('hbs_page_title')}
                    </h1>

                    <p>
                        {(t('hbs_page_valuation_date') || '')
                            .replace('[VO_marque]', vehicle.makeName)
                            .replace('[VO_modele]', vehicle.modelName)
                            .replace(
                                '[DATE au format JJ.MM.ANNNE]',
                                valuation?.date.toLocaleDateString(lang),
                            )}
                    </p>
                    {valuation && (
                        <div className="valuation-value">
                            {TextUtils.formatPrice(locale, currency, valuation.value)}
                        </div>
                    )}

                    <p className="valuation-description">{t('hbs_page_valuation_detail')}</p>

                    <p className="valuation-reference">
                        {t('reference_id')} <strong>{offerNumber}</strong>
                    </p>
                </div>
                {recordPSA && (
                    <div className="h1 h1-cyan my-4 text-center">
                        {t('hbs_argument_title_for_redirected_leads')}
                    </div>
                )}
                <div className="service-section">
                    <p className="service-section-explanation">
                        {t('hbs_page_service_explanation')}
                    </p>
                    <div className="h1 service-section-title"> {t('hbs_page_request_title')} </div>

                    <RestitutionPhoneInput customerPhone={customer.phone} />
                </div>

                <FeatureGroup>
                    <Feature
                        details="hbs_page_argument_1"
                        icon="clock"
                        style={{ minHeight: '30px' }}
                    />
                    <Feature
                        details="hbs_page_argument_2"
                        icon="lock"
                        style={{ minHeight: '30px' }}
                    />
                    <Feature
                        details="hbs_page_argument_3"
                        icon="check"
                        style={{ minHeight: '30px' }}
                    />
                </FeatureGroup>
            </Container>

            <HowToWorkSection />
            <p
                className="footnote pb-5"
                dangerouslySetInnerHTML={{
                    __html: t('hbs_page_instant_payment_conditions') || '',
                }}
            />
        </div>
    );
};

export default RadRestitution;
