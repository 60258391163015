import { useDispatch } from 'react-redux';
import { identificationTypeUseCase } from '../../../../hexagon/usecases/identificationType/identificationType.useCase';
import * as actionCreators from '../../../../hexagon/usecases/getCarDetailsByRegistration/actionCreators';
import { setRegistrationUseCase } from '../../../../hexagon/usecases/setRegistration/setRegistration.useCase';

const useVehicle = () => {
    const dispatch = useDispatch();

    const resetVehicle = (type = '') => {
        dispatch(identificationTypeUseCase(type));
        dispatch(setRegistrationUseCase(''));
        dispatch(actionCreators.Actions.setVehicleAll({}));
        dispatch(actionCreators.Actions.setVehicleNames({}));
        dispatch(actionCreators.Actions.setVehicleStateAll({}));
        dispatch(actionCreators.Actions.setParticularAll({}));
        dispatch(actionCreators.Actions.setCarDetails());
    };

    return { resetVehicle };
};

export default useVehicle;
