import { AnyAction, combineReducers } from 'redux';
import { TActionStatus } from '../appState';

export const data = (state = '', action: AnyAction) => {
    if (action.type === 'recordInformation/SUCCESS')
        return action.payload.recordType ? action.payload.recordType : null;
    return state;
};

export const status = (state: TActionStatus = 'idle', action: AnyAction) => {
    if (action.type === 'recordInformation/PENDING') return 'pending';
    if (action.type === 'recordInformation/SUCCESS') return 'succeeded';
    if (action.type === 'recordInformation/FAILED') return 'failed';

    return state;
};

export const RecordInformationReducer = combineReducers({
    status,
    data,
});
