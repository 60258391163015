import { TClient, TClientConfig } from '../../../../hexagon/interfaces';
import { ClientConfigGateway } from '../../../../hexagon/gateways/clientConfigGateway.interface';

import spoticar from '../../../../config/spoticar';

export class InMemoryClientConfigGateway implements ClientConfigGateway {
    getConfig(name: TClient): TClientConfig {
        if (name === 'spoticar') return spoticar;

        return spoticar;
    }
}
