import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Col, Row } from 'reactstrap';

import { useHistory } from 'react-router';
import { TRecord } from '../../../../hexagon/interfaces';
import { TextUtils } from '../../../../hexagon/shared/utils/TextUtils';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { FeatureGroup } from './FeatureGroup';
import { Feature } from './Feature';
import { VehicleInformations } from './VehicleInformations';
import { CtaBlock } from './CtaBlock';
import { resetRecordUseCase } from '../../../../hexagon/usecases/resetRecord/resetRecord.useCase';
import { duplicateRecordUseCase } from '../../../../hexagon/usecases/duplicateRecord/duplicateRecord.useCase';
import { getRecordSelector } from '../../view-models-generators/recordSelectors';
import useTranslation from '../hooks/useTranslation';

export const ArchivedValuation: React.FC<TRecord> = (props) => {
    const { uid, vehicle, valuation, customer, offerNumber } = props;
    const { client } = useSelector(getClientSelector);
    const { t } = useTranslation();

    const { uid: duplicateRecordUid } = useSelector(getRecordSelector);
    const { locale, currency } = client.config;
    const history = useHistory();
    const dispatch = useDispatch();

    const submitRefreshRecord = () => {
        dispatch(duplicateRecordUseCase(uid));
    };

    useEffect(() => {
        if (duplicateRecordUid.toString() !== uid) history.push(`/record/${duplicateRecordUid}`);
    }, [dispatch, duplicateRecordUid, history, uid]);

    const submitUpdateRecord = () => {
        dispatch(resetRecordUseCase());
        history.push('/');
    };

    return (
        <div className="page page-archive">
            <Container fluid className="px-0 pt-4">
                <div className="pr-4 pl-4 pb-0">
                    <h1>{t('expiration_page_title')}</h1>
                    <Row>
                        <Col>
                            <div className="page-archive-file-number">
                                {t('expiration_page_file_number')} <strong>{offerNumber}</strong>
                            </div>
                            <div className="information-box">
                                <div className="h1">{t('expiration_page_information')}</div>
                                <p>
                                    <strong>{t('expiration_page_email')}</strong> {customer.email}
                                </p>
                                <p>
                                    <strong>{t('expiration_page_zip_code')}</strong>{' '}
                                    {customer.zipCode}
                                </p>
                            </div>
                        </Col>
                        {valuation && (
                            <Col>
                                <div className="valuation-box">
                                    <div className="h1">{t('expiration_page_valuation')}</div>
                                    <div className="valuation-value">
                                        {TextUtils.formatPrice(locale, currency, valuation.value)}
                                    </div>
                                    <p>
                                        {t('expiration_page_valuation_date')}{' '}
                                        {TextUtils.formatDateNumeric(locale, valuation.date)}
                                    </p>
                                </div>
                            </Col>
                        )}
                    </Row>

                    <hr className="my-4" />

                    <h2>{t('your_car')}</h2>
                    <VehicleInformations vehicle={vehicle} />

                    <CtaBlock>
                        <Row>
                            <Col xs={12} sm={6}>
                                <Button
                                    block
                                    className="refresh-valuation"
                                    onClick={() => submitRefreshRecord()}
                                >
                                    {t('expiration_page_cta_actualize')}
                                </Button>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Button
                                    block
                                    className="modify-valuation"
                                    onClick={() => submitUpdateRecord()}
                                >
                                    {t('expiration_page_cta_modify')}
                                </Button>
                            </Col>
                        </Row>
                    </CtaBlock>
                </div>
                <FeatureGroup>
                    <Feature label="appointment_confirmation_argument_1" icon="user" />
                    <Feature label="appointment_confirmation_argument_2" icon="smile" />
                    <Feature label="appointment_confirmation_argument_3" icon="sun" />
                </FeatureGroup>
            </Container>
        </div>
    );
};
