import React, { useEffect, useState } from 'react';
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { InputWithValidation } from './InputWithValidation';
import { setParticularValue } from '../../../../hexagon/usecases/setParticularValue/setParticularValue.useCase';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { getFormSelector } from '../../view-models-generators/formSelectors';
import { checkFormValidUseCase } from '../../../../hexagon/usecases/checkFormValid/checkFormValid.useCase';
import useTranslation from '../hooks/useTranslation';
import { getRecordSelector } from '../../view-models-generators/recordSelectors';

type TPhoneInputProps = {
    required: boolean;
    scope: 'phone' | 'phone2';
    color?: string;
};

export const PhoneInput: React.FC<TPhoneInputProps> = ({ required, scope, color = '#adb5bd' }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { particular } = useSelector(getFormSelector);
    const { config } = useSelector(getClientSelector);

    const [phone, setPhone] = useState<string>('');
    const [valid, setValid] = useState<boolean | undefined>(undefined);

    const isValidPhone = (value: string) => value.search(new RegExp(config.phoneRegex)) === 0;

    const {
        data: { customer },
    } = useSelector(getRecordSelector);

    useEffect(() => {
        let telephone = '';
        if (scope === 'phone') telephone = particular.phone;
        else if (scope === 'phone2') telephone = customer.phone;
        setPhone(telephone);
        if (isValidPhone(telephone || '')) setValid(true);
        else if (telephone?.length > 0) setValid(false);
    }, []);

    const handleChange = (value: string) => {
        if (value.length <= 10) {
            if (isValidPhone(value)) {
                setValid(true);
            } else if (value.length > 0) {
                setValid(false);
            } else setValid(undefined);
            setPhone(value);
        }
    };

    useEffect(() => {
        if (valid) {
            if (scope === 'phone') dispatch(setParticularValue('phone', phone));
            else if (scope === 'phone2') {
                dispatch(setParticularValue('phone2', phone));
                if (!particular.phone) dispatch(setParticularValue('phone', phone));
            }
        } else if (scope === 'phone') dispatch(setParticularValue('phone', ''));
        else if (scope === 'phone2') dispatch(setParticularValue('phone2', ''));
    }, [dispatch, valid]);

    useEffect(() => {
        if (!phone && required === false) dispatch(checkFormValidUseCase(true));
        if (_.isBoolean(valid)) dispatch(checkFormValidUseCase(valid));
    }, [phone, valid]);

    return (
        <>
            <FormGroup className="form-group-phone">
                <Label for="phone">{required ? t('customer_phone') : t('phone')}</Label>
                <InputWithValidation>
                    <InputGroup>
                        <Input
                            type="number"
                            id="phone"
                            value={phone}
                            autoComplete="off"
                            onChange={(e) => handleChange(e.target.value)}
                            placeholder={t('phone_field')}
                        />
                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                <FontAwesomeIcon color={color} icon={faPhone} />
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                    {!_.isUndefined(phone) && (
                        <div className="input-validation input-validation-tel">
                            {valid === true && <FontAwesomeIcon icon={faCheck} />}
                            {valid === false && <FontAwesomeIcon icon={faTimes} />}
                        </div>
                    )}
                </InputWithValidation>
                {phone && !valid && <p className="text-danger small">{t('phone_error')}</p>}
            </FormGroup>
        </>
    );
};
