import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Picture } from './Picture';
import useTranslation from '../hooks/useTranslation';

const ValuationLoader = () => {
    const { t } = useTranslation();

    const [showParagraphOne, setShowParagraphOne] = useState<boolean>(false);
    const [showParagraphTwo, setShowParagraphTwo] = useState<boolean>(false);
    const [showParagraphThree, setShowParagraphThree] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setShowParagraphOne(true);
        }, 1000);

        setTimeout(() => {
            setShowParagraphTwo(true);
        }, 2000);

        setTimeout(() => {
            setShowParagraphThree(true);
        }, 3000);
    }, []);
    return (
        <div className="page page-loader">
            <Container>
                <Picture background="stopwatch" />
                <h1>{t('loading_page_title')}</h1>
                <Row>
                    <Col className="col-md-12 offset-lg-2 col-lg-8">
                        <Row
                            className={`loading-page-argument-1 ${showParagraphOne ? 'show' : ''}`}
                        >
                            <Col xs={1}>
                                <Picture background="checkbox" />
                            </Col>
                            <Col xs={11}>
                                <p>{t('loading_page_argument_1')}</p>
                            </Col>
                        </Row>
                        <Row
                            className={`loading-page-argument-2 ${showParagraphTwo ? 'show' : ''}`}
                        >
                            <Col xs={1}>
                                <Picture background="checkbox" />
                            </Col>
                            <Col xs={11}>
                                <p>{t('loading_page_argument_2')}</p>
                            </Col>
                        </Row>
                        <Row
                            className={`loading-page-argument-3 ${
                                showParagraphThree ? 'show' : ''
                            }`}
                        >
                            <Col xs={1}>
                                <Picture background="checkbox" />
                            </Col>
                            <Col xs={11}>
                                <p>{t('loading_page_argument_3')}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ValuationLoader;
