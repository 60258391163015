import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';

const { Actions } = actionCreators;

export const setUtmSource =
    (utm_source: string): ThunkResult<void> =>
    async (dispatch) => {
        dispatch(Actions.setUtmSource(utm_source));
    };
