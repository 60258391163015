import { AnyAction, combineReducers } from 'redux';

export const isPostponed = (state = false, action: AnyAction) => {
    if (action.type === 'isPostponed/PENDING') state = false;
    if (action.type === 'isPostponed/SUCCESS') state = true;
    if (action.type === 'isPostponed/FAILED') state = false;
    return state;
};

export const isPostponedReducer = isPostponed;
