import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { InputWithValidation } from './InputWithValidation';
import { InputValidation } from './InputValidation';
import useTranslation from '../hooks/useTranslation';
import { getBuyingProjectMakeListUseCase } from '../../../../hexagon/usecases/getBuyingProjectMakeList/getBuyingProjectMakeList.useCase';
import { getBuyingProjectMakeListSelector } from '../../view-models-generators/buyingProjectMakeListSelector';
import { setVehicleStateValue } from '../../../../hexagon/usecases/setVehicleStateValue/setVehicleStateValue.useCase';
import { BuyingProjectMake, TVehicleStateItem } from '../../../../hexagon/interfaces';
import { setBuyingProjectMakeUseCase } from '../../../../hexagon/usecases/setBuyingProjectMake/setBuyingProjectMake.useCase';

type MakeSelectInputProps = {
    id: TVehicleStateItem;
    label: string;
    error?: boolean;
    setErrors: any;
};

export const MakeSelectInput: React.FC<MakeSelectInputProps> = ({
    id,
    label,
    error,
    setErrors,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [value, setValue] = useState('');
    const [valid, setValid] = useState<boolean>();

    const { data: list, status } = useSelector(getBuyingProjectMakeListSelector);

    const handleChange = (fieldValue: string) => {
        setValue(fieldValue);
        dispatch(setVehicleStateValue(id, fieldValue));
        setErrors({});

        if (Number(fieldValue) !== 0) {
            const purchaseProject = _.find(
                list,
                (m: BuyingProjectMake) => m.id === Number(fieldValue),
            );
            dispatch(setBuyingProjectMakeUseCase(purchaseProject));
        } else dispatch(setBuyingProjectMakeUseCase({}));
    };

    useEffect(() => {
        dispatch(getBuyingProjectMakeListUseCase('VN'));
    }, [dispatch]);

    useEffect(() => {
        setValid(undefined);
        if (value !== '') setValid(true);
        if (status === 'failed') setValid(false);
        if (error && !value) setValid(false);
    }, [value, status, error]);

    return (
        <FormGroup className={`form-group-${id}`} id={`form_group_${id}`}>
            <Label for={id}>
                {(label && t(label)) || '\u00A0'} {/* \u00A0 specific for empty labels */}
                {status === 'pending' && <Spinner size="sm" />}
            </Label>
            <InputWithValidation>
                <select
                    className="form-control"
                    name={id}
                    id={id}
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                    disabled={status !== 'succeeded'}
                >
                    <option value="">{t('select')}</option>
                    {list.map((m) => (
                        <option key={m.id} value={m.id}>
                            {m.id === 0 ? t('buying_project_other_brands') : m.name}
                        </option>
                    ))}
                </select>

                <InputValidation valid={valid} />
            </InputWithValidation>
        </FormGroup>
    );
};
