import { AnyAction, combineReducers } from 'redux';
import { TReason } from '../../hexagon/interfaces';
import { TActionStatus } from '../appState';

export const data = (state: TReason[] = [], action: AnyAction) => {
    if (action.type === 'CancellationReasons/SUCCESS') return action.payload.cancellationReasons;
    return state;
};

export const status = (state: TActionStatus = 'idle', action: AnyAction) => {
    if (action.type === 'CancellationReasons/PENDING') return 'pending';
    if (action.type === 'CancellationReasons/SUCCESS') return 'succeeded';
    if (action.type === 'CancellationReasons/FAILED') return 'failed';
    return state;
};

export const cancellationReasonsReducer = combineReducers({
    status,
    data,
});
