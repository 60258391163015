import { RecordIds, TRecord } from '../../../../../hexagon/interfaces';

export const aRecordIds: RecordIds = {
    id: 200,
    uid: 'aaa',
    reference: 'a145zdse58bc',
};

export const somesRecords: TRecord[] = [
    {
        id: '100',
        uid: 'aaa',
        offerStatus: 'NO_APPOINTMENT',
        offerNumber: 'WITHOUT_APPOINTMENT',
        timeDetention: 0,
        isRad: true,
        vehicle: {
            makeId: '100',
            makeName: 'Peugeot',
            modelName: '2008',
            year: '2020',
            month: 'Enero',
            registrationDate: new Date(),
            bodyName: 'Sedan',
            fuelName: 'Essence',
            engineName: '92CH',
            gearName: 'automatique',
            versionName: '1.6 bluehdi 75 active',
            import: true,
            mileage: 45000,
            doors: '5',
        },
        customer: {
            email: 'john@doe.fr',
            phone: '912341234',
            phone2: '912341234',
            name: 'john',
            zipCode: '12345',
        },
    },
    {
        id: '200',
        uid: 'bbb',
        offerStatus: 'CONFIRMED',
        offerNumber: '11BB22',
        timeDetention: 10000,
        isRad: false,
        vehicle: {
            makeId: '100',
            makeName: 'Fiat',
            modelName: 'Panda',
            year: '2020',
            month: 'Enero',
            registrationDate: new Date(),
            bodyName: 'Sedan',
            fuelName: 'Essence',
            engineName: '92CH',
            gearName: 'automatique',
            versionName: '1.6 bluehdi 75 active',
            import: true,
            mileage: 15000,
            doors: '5',
        },
        customer: {
            email: 'john@doe.fr',
            phone: '912341234',
            phone2: '912341234',
            name: 'john',
            zipCode: '12345',
        },
        valuation: {
            privateValue: 5000,
            value: 6000,
            date: new Date('2020-08-20'),
        },
        appointment: {
            id: 39132,
            createdAt: '2021-09-16 12:18:51',
            updatedAt: '0000-00-00 00:00:00',
            status: Boolean(1),
            lastOne: 'yes',
            active: 'yes',
            appointmentDate: 'Vendredi, 22 de avril',
            startHour: '15:00',
            endHour: '1600',
            expertId: 1636907,
            expertName: 'Expert PDV',
            expertPicture: 'http://static.shakazoola.com/temp/AUTOBIZ/1630467_Avatar.jpg',
            networkId: 5701,
            dealerId: 503546,
            dealerName: 'TEST PDV 2',
            realDealerName: 'real TEST PDV 2',
            address: 'Paris, test, 70',
            zipCode: 25633,
            city: 'Paris',
            phone: '123466',
            latitude: 1.2,
            longitude: 1.6,
            addressPrecision: '',
            smsConfirmation: false,
        },
    },
    {
        id: '300',
        uid: 'ccc',
        offerStatus: 'EXPIRED',
        offerNumber: 'EXPIRED',
        timeDetention: 0,
        isRad: false,
        vehicle: {
            makeId: '100',
            makeName: 'Renault',
            modelName: 'Clio',
            year: '2020',
            month: 'Enero',
            registrationDate: new Date(),
            bodyName: 'Sedan',
            fuelName: 'Essence',
            engineName: '92CH',
            gearName: 'automatique',
            versionName: '1.6 bluehdi 75 active',
            import: true,
            mileage: 20000,
            doors: '5',
        },
        customer: {
            email: 'john@doe.fr',
            phone: '912341234',
            phone2: '912341234',
            name: 'john',
            zipCode: '12345',
        },
        valuation: {
            privateValue: 17000,
            value: 18000,
            date: new Date('2020-01-01'),
        },
    },
    {
        id: '400',
        uid: 'ddd',
        offerStatus: 'UNQUOTABLE',
        offerNumber: 'NOT_QUOTABLE',
        timeDetention: 0,
        isRad: true,
        vehicle: {
            makeId: '100',
            makeName: 'Peugeot',
            modelName: '2008',
            year: '2020',
            month: 'Enero',
            registrationDate: new Date(),
            bodyName: 'Sedan',
            fuelName: 'Essence',
            engineName: '92CH',
            gearName: 'automatique',
            versionName: '1.6 bluehdi 75 active',
            import: true,
            mileage: 90000,
            doors: '5',
        },
        customer: {
            email: 'john@doe.fr',
            phone: '912341234',
            phone2: '912341234',
            name: 'john',
            zipCode: '12345',
        },
    },
    {
        id: '100',
        uid: 'eee',
        offerStatus: 'NO_APPOINTMENT',
        offerNumber: 'WITHOUT_APPOINTMENT',
        timeDetention: 0,
        isRad: false,
        vehicle: {
            makeId: '100',
            makeName: 'Peugeot',
            modelName: '2008',
            year: '2020',
            month: 'Enero',
            registrationDate: new Date(),
            bodyName: 'Sedan',
            fuelName: 'Essence',
            engineName: '92CH',
            gearName: 'automatique',
            versionName: '1.6 bluehdi 75 active',
            import: true,
            mileage: 45000,
            doors: '5',
        },
        customer: {
            email: 'john@doe.fr',
            phone: '912341234',
            phone2: '912341234',
            name: 'john',
            zipCode: '12345',
        },
    },
];
