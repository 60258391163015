import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import { RecordGateway } from '../../gateways/recordGateway.interface';
import * as actionCreators from './actionCreators';

export const getCancellationReasonsUseCase =
    (): ThunkResult<void> =>
    async (dispatch, getState, { recordGateway }: { recordGateway: RecordGateway }) => {
        dispatch(actionCreators.Actions.CancellationReasonsFetching());
        const { config } = getState().client;
        const result = await recordGateway.requesCancellationReasons(config.identifier);

        if (isRight(result)) {
            dispatch(actionCreators.Actions.CancellationReasonsRetrieved(result.right));
        } else {
            dispatch(actionCreators.Actions.CancellationReasonsFailed());
        }
    };
