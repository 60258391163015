import { ActionsUnion, createAction } from '../../../redux/customActions';
import { BuyingProjectMake } from '../../interfaces';

export const Actions = {
    makeListFetching: () => createAction('buying_Project_Make/PENDING'),
    makeListFailed: () => createAction('buying_Project_Make/FAILED'),
    makeListRetrieved: (makeList: BuyingProjectMake[]) =>
        createAction('buying_Project_Make/SUCCESS', { makeList }),
};

export type ActionsType = ActionsUnion<typeof Actions>;
