import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';

const { Actions } = actionCreators;

export const setGclid =
    (gclid: string): ThunkResult<void> =>
    async (dispatch) => {
        dispatch(Actions.setGclid(gclid));
    };
