import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { getRecordSelector } from '../../view-models-generators/recordSelectors';
import { saveRecordPSAInformationUseCase } from '../../../../hexagon/usecases/saveRecordPSAInformation/saveRecordPSAInformation.useCase';
import { getRecordInformationUseCase } from '../../../../hexagon/usecases/getRecordInformation/getRecordInformation.useCase';
import RecordProcess from './RecordProcess';

export const RecordPagePSA: React.FC = () => {
    const dispatch = useDispatch();

    const { recordUid } = useParams<{ recordUid: string }>();

    const {
        data: record,
        status,
        recordType: { data: recordType, status: recordTypeStatus },
    } = useSelector(getRecordSelector);

    useEffect(() => {
        dispatch(saveRecordPSAInformationUseCase(recordUid));
    }, [dispatch, recordUid]);

    useEffect(() => {
        if (status === 'succeeded') dispatch(getRecordInformationUseCase(record.uid));
    }, [dispatch, record.offerStatus, record.uid]);

    if (status === 'pending') {
        return (
            <div className="text-center m-5 spinner-loader" id="spinner-loader">
                <Spinner />
            </div>
        );
    }

    return (
        <RecordProcess
            status={status}
            record={record}
            recordType={recordType}
            recordTypeStatus={recordTypeStatus}
        />
    );
};
