import { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { getClientSelector } from '../../view-models-generators/clientSelector';

const useTracker = (
    vehicle?: any,
    vehicleState?: any,
    particular?: any,
    displaySectionMoreDetails?: any,
    displaySectionAdditionalInformation?: any,
    privacyChecked?: any,
    canQuote?: any,
    submitting?: any,
    displaySectionPersonalDetails?: any, // todo later
) => {
    const { config } = useSelector(getClientSelector);

    const trackerPushSpoticarDetails = (message: any) => {
        TagManager.dataLayer({ dataLayer: message });
    };

    const trackerPushSpoticarEvent = (
        eventAction: string,
        eventLabel: string,
        event = 'uaevent',
        eventCategory = 'Content',
    ) => {
        const message = { event, eventCategory, eventAction, eventLabel };
        TagManager.dataLayer({ dataLayer: message });
    };

    const trackerPushSpoticarVirtualPath = (message: any) => {
        TagManager.dataLayer({ dataLayer: message });
    };

    const trackerPushEvent = (event: string, step: string) => {
        const message = { type: 'event', event, step };
        // @ts-ignore-start
        if ('parentIFrame' in window) window.parentIFrame.sendMessage(message);
        // @ts-ignore-end
    };

    const usePrevious = (value: any) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const compareObject = (oldState: any, newState: any) => {
        const combined = { ...oldState, ...newState };
        const diff = Object.entries(combined).reduce((acc: any, [key, value]) => {
            if (newState[key] !== oldState[key]) acc[key] = value;
            return acc;
        }, {});
        return diff;
    };
    const prevVehicle = usePrevious(vehicle) as any;
    const prevVehicleState = usePrevious(vehicleState) as any;
    const prevParticular = usePrevious(particular) as any;

    // Tracking Step 1 - Vehicle
    useEffect(() => {
        const diffVehicle = compareObject(prevVehicle, vehicle);
        if (diffVehicle.make)
            trackerPushSpoticarEvent('Forms::TradeinRequest::Select::Brand', 'step1_choix_marque');
        // trackerPushEvent('step1_choix_marque', 'form/information_de_base_choix_marque');
        if (diffVehicle.model)
            trackerPushSpoticarEvent('Forms::TradeinRequest::Filter::Model', 'step1_choix_modele');
        // trackerPushEvent('step1_choix_modele', 'form/information_de_base_choix_modele');
        if (diffVehicle.month)
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::EnrollmentMonth',
                'step1_choix_mois_immatriculation ',
            );
        // trackerPushEvent(
        //     'step1_choix_mois_immatriculation',
        //     'form/information_de_base_choix_mois_immatriculation',
        // );
        if (diffVehicle.year)
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::EnrollmentYear',
                'step1_choix_annee_immatriculation',
            );
        // trackerPushEvent(
        //     'step1_choix_annee_immatriculation',
        //     'form/information_de_base_choix_annee_immatriculation',
        // );
        if (diffVehicle.fuel)
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::Fuel',
                'step1_choix_carburant',
            );
        // trackerPushEvent('step1_choix_carburant', 'form/information_de_base_choix_carburant');
        if (diffVehicle.body) {
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::BodyStyle',
                'step1_choix_carrosserie',
            );
            // trackerPushEvent(
            //     'step1_choix_type_de_voiture',
            //     'form/information_de_base_choix_type_voiture',
            // );
        }

        if (diffVehicle.door) {
            trackerPushSpoticarEvent('Forms::TradeinRequest::Filter::Doors', 'step2_porte');
            // trackerPushEvent('step2_portes', 'form/mes_details_portes');
        }

        if (diffVehicle.gear) {
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::GearBox',
                'step2_boite_vitesse',
            );
            // trackerPushEvent('step2_boite_vitesse', 'form/mes_details_boite_vitesse');
        }

        if (diffVehicle.fiscal) {
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::HorsePower',
                'step2_chevaux_fiscaux',
            );
            // trackerPushEvent('step2_chevaux_fiscaux', 'form/mes_details_chevaux_fiscaux');
        }

        if (diffVehicle.engine) {
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::EnginePower',
                'step2_choix_puissance',
            );
            // trackerPushEvent('step2_choix_puissance', 'form/information_de_base_choix_puissance');
        }

        if (diffVehicle.version) {
            trackerPushSpoticarEvent('Forms::TradeinRequest::Filter::Version', 'step2_version');
            // trackerPushEvent('step2_version', 'form/mes_details_version');
        }

        if (diffVehicle.mileage) {
            trackerPushSpoticarEvent('Forms::TradeinRequest::Filter::Mileage', 'step2_kilometrage');
            // trackerPushEvent('step2_kilometrage', 'form/mes_details_kilometrage');
        }
    }, [vehicle]);

    useEffect(() => {
        const diffVehicleState = compareObject(prevVehicleState, vehicleState);

        if (diffVehicleState.running)
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::RollingCar',
                'step2_voiture_roulante',
            );
        // trackerPushEvent('step2_voiture_roulante', 'form/mes_details_voiture_roulante');

        if (diffVehicleState.imported)
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::ImportedCar',
                'step2_voiture_importée',
            );
        // trackerPushEvent('step2_voiture_importée', 'form/mes_details_voiture_importée');

        if (diffVehicleState.firstHand)
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::FirstHand',
                'step2_premiere_main',
            );
        // trackerPushEvent('step2_premiere_main', 'form/mes_details_premiere_main');

        if (diffVehicleState.history)
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::ServiceBook',
                'step2_carnet_entretien',
            );
        // trackerPushEvent('step2_carnet_entretien', 'form/mes_details_carnet_entretien');

        if (diffVehicleState.serviceInvoice)
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::Invoices',
                'step2_factures_entretien',
            );
        // trackerPushEvent('step2_factures_entretien', 'form/mes_details_factures_entretien');

        if (diffVehicleState.notRollingReason) {
            // trackerPushEvent('', '');
        }

        if (diffVehicleState.notRollingDescription) {
            // trackerPushEvent('', '');
        }
        if (diffVehicleState.purchaseProject)
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Filter::BuyingProject',
                'step2_achat_voiture',
            );
        // trackerPushEvent('step2_achat_voiture', 'form/mes_details_achat_voiture');
    }, [vehicleState]);

    useEffect(() => {
        const diffParticular = compareObject(prevParticular, particular);

        if (diffParticular.email) {
            trackerPushSpoticarEvent('Forms::TradeinRequest::Select::Email', 'step3_email');
            // trackerPushEvent('step3_email', 'form/information_email');
            if (config.emailConfirmation)
                trackerPushSpoticarEvent(
                    'Forms::TradeinRequest::Select::EmailConfirmation',
                    'step3_confirmation_email',
                );
            // trackerPushEvent('step3_confirmation_email', 'form/information_confirmation_email');
        }

        if (diffParticular.zipCode)
            // trackerPushEvent('step3_code_postal', 'form/information_code_postal');
            trackerPushSpoticarEvent('Forms::TradeinRequest::Select::ZipCode', 'step3_code_postal');
        if (diffParticular.phone)
            trackerPushSpoticarEvent(
                'Forms::TradeinRequest::Select::PhoneNumber',
                'step3_telephone',
            );
        // trackerPushEvent('step3_telephone', 'form/information_telephone');
    }, [particular]);

    useEffect(() => {
        if (displaySectionMoreDetails) {
            // trackerPushEvent('step1_validate', 'form/information_de_base');
        }
    }, [displaySectionMoreDetails]);

    useEffect(() => {
        if (displaySectionAdditionalInformation) {
            // trackerPushEvent('step2_validate', 'form/mes_details');
        }
    }, [displaySectionAdditionalInformation]);

    useEffect(() => {
        if (privacyChecked) {
            // trackerPushEvent(
            //     'step3_condition_utilisation',
            //     'form/information_condition_utilisation',
            // );
        }
    }, [privacyChecked]);

    useEffect(() => {
        if (canQuote) {
            // trackerPushEvent('step3_validate', 'form/information_additionnelle');
        }
    }, [canQuote]);

    useEffect(() => {
        if (submitting) {
            // trackerPushEvent('step4_estimation_reprise', 'form/validation_informations');
            trackerPushSpoticarEvent('Validate::TradeinRequest', 'step4_estimation_reprise');
        }
    }, [submitting]);

    return {
        compareObject,
        usePrevious,
        trackerPushSpoticarEvent,
        trackerPushSpoticarDetails,
        trackerPushSpoticarVirtualPath,
    };
};

export default useTracker;
