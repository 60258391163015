import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';

const { Actions } = actionCreators;

export const setUtmMedium =
    (utm_medium: string): ThunkResult<void> =>
    async (dispatch) => {
        dispatch(Actions.setUtmMedium(utm_medium));
    };
