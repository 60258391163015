import React from 'react';
import useTranslation from '../hooks/useTranslation';

import { Picture } from './Picture';

type TAppointmentResumeProps = {
    date: string;
    hour: string;
    placeName?: string;
    label?: boolean;
};
export const AppointmentResume: React.FC<TAppointmentResumeProps> = ({
    date,
    hour,
    placeName,
    label = true,
}: TAppointmentResumeProps) => {
    const { t } = useTranslation();
    return (
        <div className="appointment-resume">
            <div className="appointment-resume-icon">
                <Picture background="icon-calendar" />
            </div>
            <div className="appointment-resume-text">
                {label && <h1> {t('appointment_recap_date')}</h1>}
                {/* <p className="mb-2">
                    <strong>{placeName}</strong>
                </p> */}
                <div>{date}</div>
                <div>{hour}</div>
                <div>{t('appointment_recap_detail')}</div>
            </div>
        </div>
    );
};
