import { ActionsUnion, createAction } from '../../../redux/customActions';
import { TReason } from '../../interfaces';

export const Actions = {
    CancellationReasonsFetching: () => createAction('CancellationReasons/PENDING'),
    CancellationReasonsFailed: () => createAction('CancellationReasons/FAILED'),
    CancellationReasonsRetrieved: (cancellationReasons: TReason[]) =>
        createAction('CancellationReasons/SUCCESS', { cancellationReasons }),
};

export type ActionsType = ActionsUnion<typeof Actions>;
