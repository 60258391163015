import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';

const { Actions } = actionCreators;

export const setOrigin =
    (origin: string): ThunkResult<void> =>
    async (dispatch) => {
        dispatch(Actions.setOrigin(origin));
    };
