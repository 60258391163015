import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CancellationReason, TReason } from '../../../../hexagon/interfaces';
import { InputWithValidation } from './InputWithValidation';
import { InputValidation } from './InputValidation';
import useTranslation from '../hooks/useTranslation';
import { getCancellationReasonSelector } from '../../view-models-generators/cancellationReasonsSelector';
import { setCancellationReasonValueUseCase } from '../../../../hexagon/usecases/setCancellationReasonValue/setCancellationReasonValue.useCase';

type SelectInputProps = {
    scope: string;
    label: string;
    error?: boolean;
    option?: string;
};

export const ReasonInput: React.FC<SelectInputProps> = ({ scope, label, error, option }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [value, setValue] = useState('');
    const [valid, setValid] = useState<boolean>();

    const { data: list, status } = useSelector(getCancellationReasonSelector);

    const handleChange = (fieldValue: string) => {
        setValue(fieldValue);
        const cancellationReason = list.find((r) => r.label === fieldValue);
        if (cancellationReason) {
            const reason: CancellationReason = {
                id: cancellationReason.id,
                label: cancellationReason.label,
            };
            dispatch(setCancellationReasonValueUseCase(reason));
        }
    };

    useEffect(() => {
        if (value !== '') setValid(true);
        if (status === 'failed') setValid(false);
        if (error && !value) setValid(false);
    }, [value, status, error]);

    return (
        <FormGroup className={`form-group-${scope}`} id={`form_group_${scope}`}>
            <Label for={scope}>
                {(label && t(label)) || '\u00A0'} {/* \u00A0 specific for empty labels */}
                {status === 'pending' && <Spinner size="sm" />}
            </Label>
            <InputWithValidation>
                <select
                    className="form-control"
                    name={scope}
                    id={scope}
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                    disabled={status !== 'succeeded'}
                >
                    <option value="">
                        {option ? t(option) : t('pop_up_appointment_cancelled_choose_reason')}
                    </option>
                    {list.map((m: TReason) => (
                        <option key={m.id} value={m.label}>
                            {m.label}
                        </option>
                    ))}
                </select>

                <InputValidation valid={valid} />
            </InputWithValidation>
        </FormGroup>
    );
};
