import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CtaBlock } from './CtaBlock';
import useTranslation from '../hooks/useTranslation';
import { TRecord } from '../../../../hexagon/interfaces';
import { VehicleInformations } from './VehicleInformations';
import { getNotRollingSelector } from '../../view-models-generators/notRollingSelectors';
import useScroll from '../hooks/useScroll';
import useVehicle from '../hooks/useVehicle';
import { ErrorPage } from './ErrorPage';
import useTracker from '../hooks/useTracker';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { getFormSelector } from '../../view-models-generators/formSelectors';

export const CoteCQ: React.FC<TRecord> = ({ vehicle }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { scrollToElement } = useScroll();
    const { status } = useSelector(getNotRollingSelector);
    const history = useHistory();
    const { resetVehicle } = useVehicle();
    const { deviceType } = useSelector(getClientSelector).client;
    const { particular } = useSelector(getFormSelector);

    const { trackerPushSpoticarDetails, trackerPushSpoticarVirtualPath } = useTracker();
    useEffect(() => {
        trackerPushSpoticarDetails({});
        trackerPushSpoticarVirtualPath({
            event: 'updatevirtualpath',
            formsName: 'trade in request',
            virtualPageURL: 'spoticar/vendez-votre-voiture/confirmation-page-estimation-failed',
            brand: 'spoticar',
            pageName: `spoticar/reprise/central/B2C/used cars/${deviceType}/NA/no-valuation`,
            language: 'fr',
            country: 'fr',
            siteTypeLevel1: 'spoticar',
            siteTypeLevel2: 'reprise',
            siteOwner: 'central',
            siteTarget: 'B2C',
            siteFamily: 'used cars',
            formsLeadType: 'cold lead',
            formsPostalCode: `${particular.zipCode}`,
            mainStepIndicator: '2d',
            mainStepName: 'confirmation-page-estimation-failed',
            vehicleModelBodystyle: 'NA',
            vehicleModelBodystyleLabel: 'NA',
            vehicleBrand: vehicle.makeName,
        });
    }, []);

    useEffect(() => {
        if (status === 'succeeded') scrollToElement('top', 45);
    }, [dispatch, status]);

    const handleSubmit = () => {
        resetVehicle('brandModel');
        history.push('/');
    };

    if (status === 'failed') {
        return <ErrorPage />;
    }

    return (
        <div className="page page-notrolling mt-4">
            <Container fluid>
                <div className="h1">{t('no_valuation_page_title')}</div>
                <p className="mb-4 text-justify">{t('no_valuation_page_description')}</p>
                <hr />
                <div className="h1">{t('no_valuation_page_your_car')}</div>

                <VehicleInformations vehicle={vehicle} />

                <CtaBlock>
                    <Button color="primary" onClick={handleSubmit} className="mb-5">
                        {t('no_valuation_page_cta')}
                    </Button>
                </CtaBlock>
            </Container>
        </div>
    );
};
