import React, { useEffect, useState } from 'react';
import {
    Col,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { InputWithValidation } from './InputWithValidation';
import { InputValidation } from './InputValidation';
import { setParticularValue } from '../../../../hexagon/usecases/setParticularValue/setParticularValue.useCase';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { TextUtils } from '../../../../hexagon/shared/utils/TextUtils';

import { getFormSelector } from '../../view-models-generators/formSelectors';
import useTranslation from '../hooks/useTranslation';

type EmailInputProps = {
    error?: boolean;
    setErrors?: any;
};

export const EmailInput: React.FC<EmailInputProps> = ({ error, setErrors }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { config } = useSelector(getClientSelector);
    const { particular } = useSelector(getFormSelector);

    const [email, setEmail] = useState<string>('');
    const [confirmEmail, setConfirmEmail] = useState<string>('');

    const [emailValid, setEmailValid] = useState<boolean | undefined>(undefined);
    const [confirmEmailValid, setConfirmEmailValid] = useState<boolean | undefined>(undefined);
    const [formValid, setFormValid] = useState<boolean | undefined>(undefined);

    const [touchedEmail, setTouchedEmail] = useState<boolean>();
    const [touchedConfirmEmail, setTouchedConfirmEmail] = useState<boolean>();

    const handleChangeEmail = (value: string) => {
        setEmailValid(TextUtils.isEmailValid(value));
        setEmail(value);
        setTouchedEmail(false);
        setErrors({});
    };

    const handleChangeConfirmEmail = (value: string) => {
        setConfirmEmailValid(TextUtils.isEmailValid(value));
        setConfirmEmail(value);
        setTouchedConfirmEmail(false);
        setErrors({});
    };

    useEffect(() => {
        setFormValid(emailValid && email === confirmEmail);
    }, [emailValid, email, confirmEmail]);

    useEffect(() => {
        if (formValid) dispatch(setParticularValue('email', email));
        else if (particular.email) dispatch(setParticularValue('email', ''));
    }, [formValid, email, particular.email]);

    useEffect(() => {
        if (!email) setTouchedEmail(undefined);
        if (!confirmEmail) setTouchedConfirmEmail(undefined);
        if (error) {
            if (!email) setEmailValid(false);
            if (!confirmEmail) setConfirmEmailValid(false);
            setTouchedEmail(true);
            setTouchedConfirmEmail(true);
        }
    }, [error]);

    return (
        <Row>
            <Col xs={12} md={6}>
                <FormGroup className="form-group-email" id="form_group_email">
                    <Label for="email">{t('email')}</Label>
                    <InputWithValidation>
                        <InputGroup>
                            <input
                                className="form-control"
                                placeholder={t('email_field')}
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => handleChangeEmail(e.target.value)}
                                onBlur={() => setTouchedEmail(true)}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                        <InputValidation valid={touchedEmail ? emailValid : undefined} />
                    </InputWithValidation>
                    {touchedEmail && email && !emailValid && (
                        <p className="text-danger small">{t('email_error')}</p>
                    )}
                    {error && email === '' && (
                        <p className="text-danger small">{t('email_required')}</p>
                    )}
                </FormGroup>
            </Col>
            {config.emailConfirmation && (
                <Col xs={12} md={6}>
                    <FormGroup className="form-group-emailConfirmation">
                        <Label for="emailConfirmation">{t('emailConfirmation')}</Label>
                        <InputWithValidation>
                            <InputGroup>
                                <input
                                    className="form-control"
                                    placeholder={t('email_field')}
                                    type="email"
                                    autoComplete="off"
                                    id="emailConfirmation"
                                    value={confirmEmail}
                                    onChange={(e) => handleChangeConfirmEmail(e.target.value)}
                                    onBlur={() => {
                                        setTouchedConfirmEmail(true);
                                    }}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                            <InputValidation
                                valid={
                                    touchedConfirmEmail ? confirmEmailValid && formValid : undefined
                                }
                            />
                        </InputWithValidation>
                        {error && confirmEmail === '' && (
                            <p className="text-danger small">{t('email_required')}</p>
                        )}
                        {touchedConfirmEmail && confirmEmail && !confirmEmailValid && (
                            <p className="text-danger small">{t('email_error')}</p>
                        )}
                        {touchedConfirmEmail && confirmEmailValid && !formValid && (
                            <p className="text-danger small">{t('email_mismatch')}</p>
                        )}
                    </FormGroup>
                </Col>
            )}
        </Row>
    );
};
