import { AnyAction } from 'redux';

export const closePOSReducer = (state = true, action: AnyAction) => {
    if (action.type === 'checkClosePOS/FAILED') {
        return false;
    }
    if (action.type === 'checkClosePOS/SUCCESS') {
        return true;
    }
    return state;
};
