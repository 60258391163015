import React from 'react';

import { Col } from 'reactstrap';
import useTranslation from '../hooks/useTranslation';

type TIcon =
    | 'bulb'
    | 'check'
    | 'clock'
    | 'cockade'
    | 'like'
    | 'lock'
    | 'sun'
    | 'target'
    | 'user'
    | 'map'
    | 'smile';

type TFeatureProps = {
    label?: string;
    icon: TIcon;
    details?: string;
    style?: any;
};
export const Feature: React.FC<TFeatureProps> = ({
    label,
    icon,
    details,
    style,
}: TFeatureProps) => {
    const { t } = useTranslation();
    return (
        <Col xs={4}>
            <div className={`feature feature-${icon}`} style={style}>
                {t(label || '')}
            </div>
            {details && <p className="feature-text">{t(details)}</p>}
        </Col>
    );
};
