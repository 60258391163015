import { Dealer } from '../../../../../hexagon/interfaces';

export const someDealers: Dealer[] = [
    {
        id: '3650',
        name: 'CITROËN PSA RETAIL SAINT-DENIS',
        c2bName: 'Saint-Denis CITROËN',
        city: 'Saint-Denis',
        address: '43 Boulevard de la Libération, 93200 Saint-Denis',
        distance: 1,
        experts: [
            {
                id: '1629016',
                name: 'Daniel Osorio',
                firstName: 'Daniel',
                picture:
                    'http://static.shakazoola.com/b2c_fr/web/public/images/imgNonDispoExpert.png',
                link: '',
            },
            {
                id: '1636910',
                name: 'Expert 5 Test',
                firstName: 'Expert 5 Test',
                picture: 'http://static.shakazoola.com/temp/AUTOBIZ/1630467_Avatar.jpg',
                link: '',
            },
        ],
        timeDetention: 0,

        totalReviewers: 80,
        ajustement: '300',
        label: 'libelle 1',
        stars: '0',
    },
    {
        id: '1510',
        name: 'PEUGEOT PSA RETAIL MALAKOFF',
        c2bName: 'Malakoff PEUGEOT',
        city: 'Malakoff',
        address: '105 Boulevard Gabriel Péri, 92240 Malakoff',
        distance: 8,
        experts: [
            {
                id: '1629016',
                name: 'Daniel Osorio',
                firstName: 'Daniel',
                picture:
                    'http://static.shakazoola.com/b2c_fr/web/public/images/imgNonDispoExpert.png',
                link: '',
            },
            {
                id: '1636910',
                name: 'Expert 5 Test',
                firstName: 'Expert 5 Test',
                picture: 'http://static.shakazoola.com/temp/AUTOBIZ/1630467_Avatar.jpg',
                link: '',
            },
        ],
        timeDetention: 0,

        totalReviewers: 80,
        ajustement: '300',
        label: 'libelle 1',
        stars: '4',
    },
    {
        id: '1734',
        name: 'PEUGEOT PSA RETAIL ROUEN',
        c2bName: 'Rouen PEUGEOT',
        city: 'Rouen',
        address: '105 Boulevard Lorem Ipsum, 92240 Rouen',
        distance: 18,
        experts: [
            {
                id: '1629016',
                name: 'Daniel Osorio',
                firstName: 'Daniel',
                picture:
                    'http://static.shakazoola.com/b2c_fr/web/public/images/imgNonDispoExpert.png',
                link: '',
            },
            {
                id: '1636910',
                name: 'Expert 5 Test',
                firstName: 'Expert 5 Test',
                picture: 'http://static.shakazoola.com/temp/AUTOBIZ/1630467_Avatar.jpg',
                link: '',
            },
        ],
        timeDetention: 0,

        totalReviewers: 80,
        ajustement: '300',
        label: 'libelle 1',
        stars: '3',
    },
    {
        id: '1234',
        name: 'PEUGEOT PSA RETAIL GISORS',
        c2bName: 'Gisors PEUGEOT',
        city: 'Gisors',
        address: '105 Boulevard Rosum Ipsum, 92240 GISORS',
        distance: 22,
        experts: [
            {
                id: '1629016',
                name: 'Daniel Osorio',
                firstName: 'Daniel',
                picture:
                    'http://static.shakazoola.com/b2c_fr/web/public/images/imgNonDispoExpert.png',
                link: '',
            },
            {
                id: '1636910',
                name: 'Expert 5 Test',
                firstName: 'Expert 5 Test',
                picture: 'http://static.shakazoola.com/temp/AUTOBIZ/1630467_Avatar.jpg',
                link: '',
            },
        ],
        timeDetention: 0,

        totalReviewers: 80,
        ajustement: '300',
        label: 'libelle 1',
        stars: '2_5',
    },
    {
        id: '7763',
        name: 'PEUGEOT PSA RETAIL MAUX',
        c2bName: 'MAUX PEUGEOT',
        city: 'MAUX',
        address: '105 Boulevard Ipsum, 92240 MAUX',
        distance: 54,
        experts: [
            {
                id: '1629016',
                name: 'Daniel',
                firstName: 'Expert 5 Test',
                picture:
                    'http://static.shakazoola.com/b2c_fr/web/public/images/imgNonDispoExpert.png',
                link: '',
            },
            {
                id: '1636910',
                name: 'Expert 5 Test',
                firstName: 'Expert 5 Test',
                picture: 'http://static.shakazoola.com/temp/AUTOBIZ/1630467_Avatar.jpg',
                link: '',
            },
        ],
        timeDetention: 5,

        totalReviewers: 80,
        ajustement: '300',
        label: 'libelle 1',
        stars: '1_5',
    },
    {
        id: '3422',
        name: 'PEUGEOT PSA RETAIL LE HAVRE',
        c2bName: 'Le havre PEUGEOT',
        city: 'Le havre',
        address: '105 Boulevard Ipsem Lorum, 92240 LE HAVRE',
        distance: 100,
        experts: [
            {
                id: '1629016',
                name: 'Daniel Osorio',
                firstName: 'Daniel',
                picture:
                    'http://static.shakazoola.com/b2c_fr/web/public/images/imgNonDispoExpert.png',
                link: '',
            },
            {
                id: '1636910',
                name: 'Expert 5 Test',
                firstName: 'Expert 5 Test',
                picture: 'http://static.shakazoola.com/temp/AUTOBIZ/1630467_Avatar.jpg',
                link: '',
            },
        ],
        timeDetention: 20,

        totalReviewers: 80,
        ajustement: '300',
        label: 'libelle 1',
        stars: '5',
    },
];
