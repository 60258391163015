import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import { RecordGateway } from '../../gateways/recordGateway.interface';
import * as actionCreators from './actionCreators';

export const getBuyingProjectMakeListUseCase =
    (type: string): ThunkResult<void> =>
    async (dispatch, getState, { recordGateway }: { recordGateway: RecordGateway }) => {
        dispatch(actionCreators.Actions.makeListFetching());
        const { config } = getState().client;

        const result = await recordGateway.requestBuyingProjectMakes(config.identifier, type);

        if (isRight(result)) {
            dispatch(actionCreators.Actions.makeListRetrieved(result.right));
        } else {
            dispatch(actionCreators.Actions.makeListFailed());
        }
    };
