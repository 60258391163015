import { isRight } from 'fp-ts/lib/Either';
import _ from 'lodash';
import { ThunkResult } from '../../../redux/configureStore';
import { RecordGateway } from '../../gateways/recordGateway.interface';
import * as actionCreators from './actionCreators';
import { getRecordUseCase } from '../getRecord/getRecord.useCase';

export const reportAppointmentUseCase =
    (recordUid: string, hour: string, distance: number): ThunkResult<void> =>
    async (dispatch, getState, { recordGateway }: { recordGateway: RecordGateway }) => {
        dispatch(actionCreators.Actions.reportAppointmentPending());
        const { config } = getState().client;
        const { isRad } = getState().record.data;
        const result = await recordGateway.reportAppointment(
            config.identifier,
            recordUid,
            Number(hour),
            isRad,
            distance,
        );

        if (isRight(result)) {
            await dispatch(getRecordUseCase(recordUid, 'full'));
            dispatch(actionCreators.Actions.reportAppointmentSucceed());
        } else {
            dispatch(actionCreators.Actions.reportAppointmentFailed());
        }
    };
