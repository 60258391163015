import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import { RecordGateway } from '../../gateways/recordGateway.interface';
import { dislayErrorUseCase } from '../displayError/displayError.useCase';
import * as actionCreators from './actionCreators';

export const confirmationAppointmentUseCase =
    (): ThunkResult<void> =>
    async (dispatch, getState, { recordGateway }: { recordGateway: RecordGateway }) => {
        dispatch(actionCreators.Actions.ConfirmAppointmentPending());
        const { record } = getState();
        const { config } = getState().client;

        const result = await recordGateway.confirmAppointment(config.identifier, record.uid);

        if (isRight(result)) {
            dispatch(actionCreators.Actions.ConfirmAppointmentSucceed());
        } else {
            dispatch(actionCreators.Actions.ConfirmAppointmentFailed());
            dispatch(dislayErrorUseCase('confirm_appointment_failed'));
        }
    };
