import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from './Loader';
import { ErrorPage } from './ErrorPage';
import { ArchivedValuation } from './ArchivedValuation';
import { Confirmation } from './Confirmation';
import { NotRolling } from './NotRolling';
import { CoteCQ } from './CoteCQ';
import RadRestitution from './RadRestitution';
import { HbsAppointment } from './HbsAppointment';
import { AppointmentOnly } from './AppointmentOnly';
import { AppointmentNoPOS } from './AppointmentNoPOS';
import { TActionStatus } from '../../../../redux/appState';
import useScroll from '../hooks/useScroll';
import { TRecord, TRecordType } from '../../../../hexagon/interfaces';
import { createIndicatorUseCase } from '../../../../hexagon/usecases/createIndicator/createIndicator.useCase';

const RecordProcess = ({
    status,
    record,
    recordType,
    recordTypeStatus,
}: {
    status: TActionStatus;
    record: TRecord;
    recordType: TRecordType;
    recordTypeStatus: TActionStatus;
}) => {
    const dispatch = useDispatch();
    const { scrollToElement } = useScroll();

    useEffect(() => {
        if (status === 'succeeded') scrollToElement('top', 45);
    }, [dispatch, status]);

    useEffect(() => {
        if (recordType === 'APPOINTMENT_ONLY')
            dispatch(createIndicatorUseCase(record.uid, 'spoticar_appointment_only', 1));
        if (recordType === 'HBS_ONLY')
            dispatch(createIndicatorUseCase(record.uid, 'spoticar_hbs_only', 1));
        if (recordType === 'HBS_APPOINTMENT')
            dispatch(createIndicatorUseCase(record.uid, 'spoticar_hbs_appointment', 1));
    }, [dispatch, recordType]);

    if (status === 'failed') {
        return <ErrorPage />;
    }

    let component;

    switch (record.offerStatus) {
        default:
        case 'UNQUOTABLE':
        case 'NOT_ROLLING':
            component = <NotRolling {...record} />;
            break;

        case 'CONFIRMED':
            component = <Confirmation {...record} />;
            break;

        case 'EXPIRED':
            component = <ArchivedValuation {...record} />;
            break;
    }

    switch (recordType) {
        case 'COTE_CQ':
            component = <CoteCQ {...record} />;
            break;
        case 'HBS_ONLY':
            component = <RadRestitution {...record} />;
            break;
        case 'HBS_APPOINTMENT':
            component = <HbsAppointment {...record} />;
            break;

        case 'APPOINTMENT_ONLY':
            component = <AppointmentOnly {...record} />;
            break;

        case 'APPOINTMENT_NO_POS':
            component = <AppointmentNoPOS {...record} />;
            break;
        default:
            break;
    }

    return (
        <div className="page page-record" id="top">
            <Loader status={recordTypeStatus}>{component}</Loader>
        </div>
    );
};

export default RecordProcess;
