import { ActionsUnion, createAction } from '../../../redux/customActions';
import { FormValue } from '../../interfaces';

export const Actions = {
    carDetailsLoading: () => createAction('carDetails/PENDING'),
    carDetailsFailed: () => createAction('carDetails/FAILED'),
    carDetailsRetrieved: () => createAction('carDetails/SUCCESS'),
    setCarDetails: () => createAction('carDetails/SET'),
    setVehicleAll: (formValue: FormValue) => createAction('vehicle/SETALL', { formValue }),
    setVehicleNames: (formValue: FormValue) => createAction('vehicle/SETNAMES', { formValue }),
    setVehicleStateAll: (formValue: FormValue) =>
        createAction('vehicleState/SETALL', { formValue }),
    setParticularAll: (formValue: FormValue) => createAction('particular/SETALL', { formValue }),
};

export type ActionsType = ActionsUnion<typeof Actions>;
