import { TReason } from '../../../../../hexagon/interfaces';

export const someReasons: TReason[] = [
    {
        id: 1,
        label: "L'offre proposée est trop basse",
    },
    {
        id: 2,
        label: 'Le garage est trop éloigné de chez moi',
    },
    {
        id: 3,
        label: "J'ai déjà vendu mon véhicule à un particulier",
    },
    {
        id: 4,
        label: "J'ai un empêchement",
    },
    {
        id: 5,
        label: "J'ai déjà vendu mon véhicule à un professionnel",
    },
    {
        id: 6,
        label: 'Les avis sur le professionnel sont mauvais',
    },
    {
        id: 7,
        label: 'Je veux garder ma voiture',
    },
    {
        id: 8,
        label: 'Mon véhicule est accidenté',
    },

    {
        id: 10,
        label: 'Véhicule Hors Process',
    },
    {
        id: 11,
        label: 'Manque de disponibilités du pro',
    },
    {
        id: 12,
        label: 'Raison financière du pro',
    },
    {
        id: 13,
        label: 'Le véhicule est trop kilométré',
    },
    {
        id: 14,
        label: 'Le véhicule est trop ancien',
    },
    {
        id: 9,
        label: 'Autres raisons',
    },
];
