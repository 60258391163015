import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';

export const checkClosePOSUseCase =
    (closePOS: boolean): ThunkResult<void> =>
    async (dispatch) => {
        if (closePOS) {
            dispatch(actionCreators.Actions.checkClosePOSSucceed());
        } else {
            dispatch(actionCreators.Actions.checkClosePOSFailed());
        }
    };
