import { Mapper } from '../../../../../hexagon/infra/Mapper';
import { Dealer } from '../../../../../hexagon/interfaces';
import { AutobizDealerDto } from '../dtos/dealerDto';

export class DealerMapper implements Mapper<Dealer> {
    static toApp(dto: AutobizDealerDto): Dealer {
        const expertList = dto.experts.map((expert) => {
            if (expert.picture.includes('shakazoola'))
                return { ...expert, picture: expert.picture.replace('https', 'http') };
            return expert;
        });

        return {
            id: dto.networkId.toString(),
            name: dto.name,
            c2bName: dto.c2bName,
            city: dto.city,
            address: dto.adress,
            distance: Number(dto.distance),
            experts: expertList,
            timeDetention: dto.timeDetention,
            totalReviewers: dto.totalReviewers,
            ajustement: dto.ajustement,
            label: dto.label,
            stars: dto.stars,
        };
    }
}
